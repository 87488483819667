import { Button, Cell, Grid } from '@teamsnap/teamsnap-ui';
import InvoiceStatus from 'components/baseComponents/InvoiceStatus';
import { Header, Heading, Panel } from 'components/teamsnapUIExtensions';
import { invoicePropShape } from 'interfaces/invoice';
import { historyPropShape } from 'interfaces/navigation';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from 'utils/date-service';
import { linkInvoices } from 'utils/links';
import styles from './InvoiceSummaryOverview.module.scss';

class InvoiceSummaryOverview extends PureComponent {
  // renderMemberEmails = memberEmails => (
  //   <ul className={ styles.Contacts }>
  //     { memberEmails.map(member => (
  //       <li key={ member.id } className={ styles.ContactList }>
  //         <span className={ styles.ContactItem }>{ member.email }</span>
  //       </li>
  //     )) }
  //   </ul>
  // )

  renderOverviewFooter = () => {
    const { invoice, showMessageModal, showCancelModal } = this.props;
    return (
      <div>
        <Button onClick={ showMessageModal } color="primary" style={ { marginRight: '10px' } } icon="mail">
          Resend Invoice
        </Button>
        { invoice.isCancelable === 'true' && (
          <Button icon="dismiss" color="negative" onClick={ showCancelModal }>
            Cancel Invoice
          </Button>
        ) }
      </div>
    );
  };

  renderTransactionOverviewFooter = () => {
    const { invoice, showAdjustmentModal } = this.props;
    return invoice.balance > 0 ? (
      <Button onClick={ showAdjustmentModal } style={ styles.AddAdjustment }>
        Adjust Balance
      </Button>
    ) : null;
  };

  renderMessagesFooter = () => (
    <Button onClick={ this.props.showMessageModal } color="primary" icon="send">
      Message Contacts
    </Button>
  );

  renderHeader = (invoice) => (
    <Header componentStyles={ styles }>
      <Heading size={ 1 }>
        <Link to={ linkInvoices(invoice[this.props.groupType], invoice.batchInvoiceId) }>{ invoice.title }</Link>
        <span className={ styles.HeadingDivider }> / </span>
        Invoice #{ invoice.id }
      </Heading>

      <Button
        onClick={ () => this.props.history.push(linkInvoices(invoice[this.props.groupType], invoice.batchInvoiceId)) }
        icon="left"
      >
        Back to Batch
      </Button>
    </Header>
  );

  render() {
    const { invoice, dateFormat } = this.props;
    let paymentUrl = invoice.paymentUrl;

    // Allow DEV to work on multiple host
    if (process.env.NODE_ENV === 'development') {
      paymentUrl = paymentUrl && paymentUrl.replace('localhost:3001', window.location.host);
    }

    // TODO: Add edit date popup to DueDate
    return (
      <section>
        { this.renderHeader(invoice) }

        <Grid isWithGutter>
          <Cell mods="u-size1of2">
            <Panel title="Invoice Overview"
              componentStyles={ styles }
              footerComponent={ this.renderOverviewFooter() }
            >
              <InvoiceStatus
                status={ invoice.status }
                hasPaymentSchedule={ invoice.hasPaymentSchedule }
                errorCount={ invoice.failedInvoicePaymentsCount }
                currentValue={ invoice.balance }
                totalValue={ invoice.subTotal }
                hasLabel
              />

              <ul className={ styles.InvoicesOverview }>
                <li>
                  Recipient :<span>{ invoice.invoiceTo }</span>
                </li>
                <li>
                  Date Sent :<span>{ formatDate(invoice.createdAt, dateFormat) }</span>
                </li>
                <li>
                  Due Date :<span>{ formatDate(invoice.dueAt, dateFormat) }</span>
                </li>
                <li>
                  URL : <a href={ paymentUrl }>{ paymentUrl }</a>
                </li>
              </ul>
            </Panel>
          </Cell>

          <Cell mods="u-size1of2">
            <Panel title="Transactions Overview"
              componentStyles={ styles }
              footerComponent={ this.renderTransactionOverviewFooter() }
            >
              <ul className={ styles.InvoiceTransaction }>
                <li className={ styles.InvoiceTransactionItem }>
                  Sub Total
                  <span className={ styles.InvoiceTransactionAmount }>{ invoice.subTotalWithCurrency }</span>
                </li>
                <li className={ styles.InvoiceTransactionItem }>
                  Online Fees
                  <span className={ styles.InvoiceTransactionAmount }>
                    { invoice.processingFeesSubTotalWithCurrency }
                  </span>
                </li>
              </ul>

              <ul className={ styles.InvoiceTransaction }>
                <li className={ styles.InvoiceTransactionItem }>
                  Payments
                  <span className={ styles.InvoiceTransactionAmount }>
                    { invoice.amountPaidWithProcessingFeesPaidWithCurrency }
                  </span>
                </li>
                <li className={ styles.InvoiceTransactionItem }>
                  Refunds
                  <span className={ styles.InvoiceTransactionAmount }>
                    { invoice.amountRefundedWithProcessingFeesRefundedWithCurrency }
                  </span>
                </li>
                <li className={ styles.InvoiceTransactionItem }>
                  Adjustments
                  <span className={ styles.InvoiceTransactionAmount }>
                    { invoice.adjustmentsWithCurrency }
                  </span>
                </li>
              </ul>

              <h4 className={ styles.InvoiceBalance }>
                Balance
                <span>{ invoice.balanceWithCurrency }</span>
              </h4>
            </Panel>
          </Cell>

          { /* TODO: Add messages sent from API when available */ }
          { /* <Cell>
            <Panel title='Messages Overview' componentStyles={ styles } footerComponent={ this.renderMessagesFooter() }>
              <h4 className={ styles.MessagesBorder }>
                Messages Sent :
                <span>0</span>
              </h4>
              <h4 className={ styles.Messages }>
                Available Contacts :
              </h4>
              { memberEmails.length > 0 && this.renderMemberEmails(memberEmails) }
            </Panel>
          </Cell> */ }
        </Grid>
      </section>
    );
  }
}

InvoiceSummaryOverview.propTypes = {
  invoice: PropTypes.shape(invoicePropShape).isRequired,
  // memberEmails: PropTypes.arrayOf(PropTypes.object),
  showMessageModal: PropTypes.func.isRequired,
  showCancelModal: PropTypes.func.isRequired,
  showAdjustmentModal: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  groupType: PropTypes.string,
  history: PropTypes.shape(historyPropShape).isRequired,
};

InvoiceSummaryOverview.defaultProps = {
  // memberEmails: [],
  groupType: null,
};

export default InvoiceSummaryOverview;
