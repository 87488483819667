import { isCurrency } from 'utils/validator';

export const validate = (values, props) => {
  const errors = {};

  const defaultError = 'Field can not be blank';
  const fields = ['kind', 'amount', 'detail'];

  fields.forEach((field) => {
    if (!values[field]) {
      errors[field] = defaultError;
    }

    if (field === 'amount') {
      const value = values[field];
      const { amountWithProcessingFee, amountRefunded, amount } = props.invoicePayment;

      // check for positive values only
      if (value <= 0) {
        errors[field] = 'Amount must be a positive number';
      }

      // check amount <= total
      if (value > amountWithProcessingFee) {
        errors[field] = 'Amount must not be greater than total';
      }

      if((amountRefunded > 0 || value < amountWithProcessingFee) && value > amount) {
        errors[field] = 'Cannot refund online fees as part of a partial refund';
      }

      // check for only numbers , or .
      const parsed = isCurrency(value);

      if (!parsed.valid) {
        errors[field] = parsed.message;
      }
    }
  });

  return errors;
};
