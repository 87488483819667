import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import { ERROR } from 'components/teamsnapUIExtensions/Feedback/Feedback';
import InvoicingFormNav from 'components/baseComponents/InvoicingFormNav';
import InvoicingScheduledPayment from 'components/baseComponents/InvoicingScheduledPayment';
import { ToggleField } from 'components/baseComponents/Fields';
import { Loader, Form, FormGroup } from 'components/teamsnapUIExtensions';
import { linkInvoices, linkInvoicingMembers } from 'utils/links';
// eslint-disable-next-line
import { Icon, Button, Panel, PanelBody, PanelRow, PanelHeader, PanelFooter, Cell, Grid } from '@teamsnap/teamsnap-ui';
import styles from './InvoicingPaymentInfo.module.scss';
import { validate } from './validate';
import { historyPropShape } from 'interfaces/navigation';

class InvoicingPaymentInfo extends Component {
  state = {
    scheduleShown: false,
    passFees: true,
  };

  redirectToInvoicingMembers = (groupId, batchInvoiceId) =>
    this.props.history.push(linkInvoicingMembers(groupId, batchInvoiceId));

  redirectToInvoices = (groupId, batchInvoiceId, needsRefresh) => {
    if (needsRefresh) {
      window.location.assign(linkInvoices(groupId, batchInvoiceId));
    } else {
      this.props.history.push(linkInvoices(groupId, batchInvoiceId));
    }
  };

  onSubmit = (props) => {
    const { groupId, batchInvoiceId, createBatchInvoicePaymentSchedule, showFeedback, updateBatchInvoice } = this.props;

    if (props.installments) {
      return createBatchInvoicePaymentSchedule(batchInvoiceId, props.scheduled)
        .then(() =>
          updateBatchInvoice({
            id: batchInvoiceId,
            processing_fee_paid_by: this.state.passFees ? 'payer' : 'payee',
          }),
        )
        .then(() => this.redirectToInvoicingMembers(groupId, batchInvoiceId))
        .catch(() => showFeedback(this.feedbackErrorProps));
    }

    return updateBatchInvoice({
      id: batchInvoiceId,
      processing_fee_paid_by: this.state.passFees ? 'payer' : 'payee',
    })
      .then(() => this.redirectToInvoicingMembers(groupId, batchInvoiceId))
      .catch(() => showFeedback(this.feedbackErrorProps));
  };

  setPassFees = (e) => {
    this.setState({ passFees: e.target.checked });
  };

  hideShowSchedule = (e) => {
    this.setState({ scheduleShown: e.target.checked });
  };

  feedbackErrorProps = {
    title: 'Oops!',
    // eslint-disable-next-line
    message:
      'Something went wrong on our end. Please try again, and if the error persists let us know at https://helpme.teamsnap.com/?solvvy=true so we can see what’s going on.',
    type: ERROR,
  };

  renderFooter = (groupId, groupType, submitting, batchInvoiceId, needsRefresh) => (
    <footer>
      <Button
        type="button"
        color="negative"
        icon="dismiss"
        mods="u-spaceRightSm"
        onClick={ () => this.redirectToInvoices(groupId, batchInvoiceId, needsRefresh) }
      >
        Cancel
      </Button>

      <Button type="submit" color="primary" isDisabled={ submitting } iconPosition="right" icon="right">
        { groupType === 'teamId' ? 'Confirm & Continue' : 'Save & Continue' }
      </Button>
    </footer>
  );

  renderPaymentOption = (text) => (
    <Grid isFit>
      <Cell mods="u-size1of2">
        <span style={ { fontWeight: '600' } }>{ text }</span>
      </Cell>
      <Cell mods="u-size1of2 u-textRight">
        <Icon name="check" style={ { fontSize: '10px', color: '#7FC243' } } />
        <span>Active</span>
      </Cell>
    </Grid>
  );

  render() {
    const {
      groupId,
      batchInvoiceId,
      handleSubmit,
      submitting,
      dateFormat,
      isFetching,
      needsRefresh,
      groupType,
    } = this.props;

    // Return Loader if still fetching items
    if (isFetching) {
      return <Loader message="random" />;
    }

    return (
      <section>
        <InvoicingFormNav
          title="New Invoice"
          currentStep={ 'paymentStep' }
          groupId={ groupId }
          invoicingId={ batchInvoiceId }
          groupType={ groupType }
        />

        <Form handleSubmit={ handleSubmit(this.onSubmit) }>
          <Panel>
            <PanelHeader title="Payment Info" />
            <PanelBody>
              <PanelRow>
                <Grid isWithGutter isFit>
                  <Cell mods="u-size1of2">
                    <FormGroup title="Payment Options" />
                    <Grid isFit>
                      <Cell mods="u-size1of2">
                        <h4>Installment Payments</h4>
                      </Cell>
                      <Cell mods="u-size1of2">
                        { groupType === 'divisionId' ? (
                          <ToggleField
                            name="installments"
                            label=""
                            className={ styles.InvoiceToggle }
                            onChange={ (e) => this.hideShowSchedule(e) }
                          />
                        ) : (
                          'Coming Soon!'
                        ) }
                      </Cell>
                    </Grid>
                    { this.state.scheduleShown ? (
                      <section>
                        <hr className="Divider" />
                        <Grid isWithGutter mods="u-spaceBottomLg u-spaceTopMd">
                          <Cell mods="u-size5of12">Installment 1</Cell>
                          <Cell mods="u-size1of12" />
                          <Cell mods="u-size5of12">
                            <strong>Collected at Checkout</strong>
                          </Cell>
                        </Grid>
                        <FieldArray name="scheduled" props={ { dateFormat } } component={ InvoicingScheduledPayment } />
                        <hr className="Divider" style={ { margin: '16px 0' } } />
                        <p className="note">* The invoice total will be divided evenly among installments</p>
                      </section>
                    ) : null }
                  </Cell>

                  <Cell mods="u-size1of2">
                    <FormGroup title="Payment Methods" />

                    { this.renderPaymentOption('Credit Card Payments') }

                    <hr className="Divider" style={ { margin: '16px 0' } } />

                    { groupType === 'divisionId' && (
                      <Grid isFit>
                        <Cell mods="u-size3of4">
                          <h4 className="u-spaceNone">Pass online fees on to recipient</h4>
                          <small>
                            { this.state.passFees
                              ? 'Online fees will appear as an additional line item on the recipients invoice.'
                              : 'Online fees paid by organization.' }
                          </small>
                        </Cell>
                        <Cell mods="u-size1of4">
                          <ToggleField
                            name="batchInvoice.passTransactionFees"
                            label=""
                            className={ styles.InvoiceToggle }
                            onChange={ (e) => this.setPassFees(e) }
                          />
                        </Cell>
                      </Grid>
                    ) }

                    { groupType === 'teamId' && (
                      <Grid isFit>
                        <Cell>Note: Online fees are passed on to the recipient</Cell>
                      </Grid>
                    ) }
                  </Cell>
                </Grid>
              </PanelRow>
            </PanelBody>
            <PanelFooter>
              { this.renderFooter(groupId, groupType, submitting, batchInvoiceId, needsRefresh) }
            </PanelFooter>
          </Panel>
        </Form>
      </section>
    );
  }
}

InvoicingPaymentInfo.propTypes = {
  history: PropTypes.shape(historyPropShape).isRequired,
  createBatchInvoicePaymentSchedule: PropTypes.func.isRequired,
  updateBatchInvoice: PropTypes.func.isRequired,
  showFeedback: PropTypes.func.isRequired,
  batchInvoiceId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  dateFormat: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  needsRefresh: PropTypes.bool.isRequired,
  groupType: PropTypes.string.isRequired,
};

export default reduxForm({
  form: 'invoicingPayment',
  validate,
})(InvoicingPaymentInfo);
