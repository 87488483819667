import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SummaryList } from '@teamsnap/teamsnap-ui';
import styles from '../PayInvoice.module.scss';
import { invoicePaymentOptionAmountsPropShape } from 'interfaces/financials';
import { invoicePropShape } from 'interfaces/invoice';

class CheckoutSummary extends PureComponent {
  hasPaymentSchedule = () => {
    const {
      isInstallmentPlan,
      invoice: { canPayByPaymentSchedule },
    } = this.props;
    return canPayByPaymentSchedule && isInstallmentPlan;
  };

  render() {
    const {
      invoicePaymentOptionAmounts: {
        invoiceBalanceWithCurrency,
        processingFeeWithCurrency,
        processingFee,
        paymentTotalWithCurrency,
      },
      invoiceSchedulePaymentsPaymentOptionAmounts,
    } = this.props;

    let paymentBalance = invoiceBalanceWithCurrency;
    let paymentFee = processingFee;
    let paymentFeeWithCurrency = processingFeeWithCurrency;
    let paymentTotal = paymentTotalWithCurrency;

    if (this.hasPaymentSchedule()) {
      const activePayment = invoiceSchedulePaymentsPaymentOptionAmounts.find((payment) => payment.isDeposit);

      paymentBalance = activePayment.paymentAmountWithCurrency;
      paymentFee = activePayment.processingFee;
      paymentFeeWithCurrency = activePayment.processingFeeWithCurrency;
      paymentTotal = activePayment.paymentTotalWithCurrency;
    }

    const items = [{ description: 'Amount Due', value: paymentBalance }];

    if (paymentFee > 0) {
      items.push({ description: 'Online Fee', value: paymentFeeWithCurrency });
    }

    return (
      <section className={ styles.Component }>
        <SummaryList
          items={ items }
          heading="Checkout Summary"
          subHeading="Amount"
          footer={ { description: 'Checkout Total:', value: paymentTotal } }
        />
      </section>
    );
  }
}

CheckoutSummary.propTypes = {
  invoice: PropTypes.shape(invoicePropShape).isRequired,
  invoicePaymentOptionAmounts: PropTypes.shape(invoicePaymentOptionAmountsPropShape).isRequired,
  invoiceSchedulePaymentsPaymentOptionAmounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isInstallmentPlan: PropTypes.bool,
};

CheckoutSummary.defaultProps = {
  isInstallmentPlan: false,
};

export default CheckoutSummary;
